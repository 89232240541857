import { Link } from '@remix-run/react';

import {
  type ModelsPromptConfig,
  type ModelsTrainingProfile,
} from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { usePromptTemplateEditorModal } from '../PromptTemplate/PromptTemplateEditor';

function PromptTemplateLink(props: {
  config?: ModelsPromptConfig | null;
  inlineEdit?: boolean;
}): JSX.Element {
  const { config } = props;
  const openEditorModal = usePromptTemplateEditorModal();
  if (!config) return <span>N/A</span>;

  const onEdit = async () => {
    const resp = await apiService.promptTemplate.getTemplateById(
      config.templateId
    );
    openEditorModal(resp.data.promptTemplate);
  };

  if (props.inlineEdit) {
    return (
      <button type='button' onClick={onEdit} className='underline'>
        {config.templateName}
      </button>
    );
  }
  return (
    <Link
      to={`/admin/prompt-templates/${config.templateId}`}
      className='underline'
    >
      {props.config?.templateName ?? 'N/A'}
    </Link>
  );
}

export function TrainingProfileQuickLook(props: {
  profile: ModelsTrainingProfile;
  inlineEdit?: boolean;
}) {
  const { profile, inlineEdit } = props;
  return (
    <table className='border-separate' style={{ borderSpacing: '8px 0' }}>
      <tbody>
        <tr>
          <td>Module Outline</td>
          <td>
            <PromptTemplateLink
              config={profile.moduleOutline}
              inlineEdit={inlineEdit}
            />
          </td>
        </tr>
        <tr>
          <td>Topic Outline</td>
          <td>
            <PromptTemplateLink
              config={profile.topicOutline}
              inlineEdit={inlineEdit}
            />
          </td>
        </tr>
        <tr>
          <td>Topic Block Overview</td>
          <td>
            <PromptTemplateLink
              config={profile.topicBlockOverview}
              inlineEdit={inlineEdit}
            />
          </td>
        </tr>
        <tr>
          <td>Block Dialogue</td>
          <td>
            <PromptTemplateLink
              config={profile.blockDialogue}
              inlineEdit={inlineEdit}
            />
          </td>
        </tr>
        <tr>
          <td>Block Bg Music</td>
          <td>
            <PromptTemplateLink
              config={profile.blockBgMusic}
              inlineEdit={inlineEdit}
            />
          </td>
        </tr>
        {profile.blocks?.map((block, i) => (
          <tr key={i}>
            <td>Block - {block.type}</td>
            <td>
              <PromptTemplateLink config={block} inlineEdit={inlineEdit} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
