import { Link } from '@remix-run/react';
import Uppy, { type UppyFile } from '@uppy/core';
import { FileInput } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import truncate from 'lodash/truncate';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Select from 'react-select';
import { match, P } from 'ts-pattern';

import {
  ClientChunkingMethod,
  type ClientDocLoader,
  type ClientDocLoaderConfigItem,
  type ClientLLMProviderItem,
  type DtoGenerateTrainingBlockResponse,
  type DtoGenerateTrainingOutlineRequest,
  type DtoLLMSearchSettings,
  type DtoLLMSettings,
  type DtoLoadDocumentParams,
  type DtoLoadDocumentResponse,
  type DtoMakeTrainingCourseRequest,
  type DtoTrainingCourseGroup,
  type ModelsTrainingProfile,
  type SchemaTrainingTopicOutline,
  type SearchDocsParams,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { getLogger } from '../../logger/logger';
import { apiService } from '../../services/api-service';
import { err2s, uuidv4 } from '../../utils/common';
import { getToken } from '../../utils/getToken';
import { buildReactSelectStyles } from '../../utils/react-select';
import { CollapsibleSection } from '../common/CollapsibleSection';
import { type Option } from '../common/Utilities';
import { DeleteIcon } from '../icons/DeleteIcon';
import { Loading } from '../Loading';
import {
  LLMModelSelect,
  LLMProviderSelect,
} from '../PromptTemplate/PromptTemplateVendor';
import { TrainingProfilePicker } from '../TrainingProfile/TrainingProfilePicker';
import { TrainingProfileQuickLook } from '../TrainingProfile/TrainingProfileQuickLook';

const logger = getLogger().scoped('doc-intelligence');

export type LocalFile = {
  status: 'none' | 'uploading' | 'failed' | 'success';
  file: UppyFile;
};

function Uploader(props: {
  baseUrl: string;
  loaderConfigItems: ClientDocLoaderConfigItem[];
  namespace: string;
  addMessage: (message: Message) => void;
}) {
  const { baseUrl, namespace, loaderConfigItems, addMessage } = props;
  const [localFile, setLocalFile] = useState<LocalFile | null>(null);
  const [error, setError] = useState<string | null>(null);

  const docLoaderMap = useMemo(() => {
    const m = new Map<string, ClientDocLoader[]>();
    for (const item of loaderConfigItems) {
      m.set(item.mimeTypeOrExt, item.loaders);
    }
    return m;
  }, [loaderConfigItems]);

  const [loader, setLoader] = useState<ClientDocLoader | null>(null);
  const [chunkingMethod, setChunkingMethod] =
    useState<ClientChunkingMethod | null>(null);
  const [prompt, setPrompt] = useState<string>('');

  const loaders = useMemo(() => {
    if (!localFile?.file.name || !localFile.file.type) return [];
    const ext = localFile.file.name.split('.').pop()?.toLowerCase();
    const key = ext ? `.${ext}` : localFile.file.type;
    const loaders = docLoaderMap.get(key) ?? [];
    setLoader(loaders[0] ?? null);
    return loaders;
  }, [docLoaderMap, localFile?.file.name, localFile?.file.type]);

  const chunkingMethods = useMemo(() => {
    const values = Object.values(ClientChunkingMethod);
    const options = values.map((value) => ({ label: value, value }));
    setChunkingMethod(options[0]?.value ?? null);
    return options;
  }, []);

  const updateExtraHeaders = useLiveCallback(
    (headers: Record<string, string>) => {
      const params: DtoLoadDocumentParams = {};
      if (loader) params.loaderName = loader.name;
      if (prompt) params.prompt = prompt;
      if (namespace) params.namespace = namespace;
      if (chunkingMethod) params.chunkingMethod = chunkingMethod;
      headers['x-lp-load-doc-params'] = JSON.stringify(params);
    }
  );

  const uppy = useMemo(() => {
    const instance = new Uppy({
      logger,
      autoProceed: false,
      debug: true,
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize: 300 * 1024 * 1024,
        allowedFileTypes: Array.from(docLoaderMap.keys()),
      },
      onBeforeUpload: (files) => {
        const normalized: { [key: string]: UppyFile } = {};
        Object.entries(files).forEach(([key, file]) => {
          const filename = encodeURIComponent(file.meta.name);
          if (file.meta.encoded) {
            normalized[key] = file;
          } else {
            normalized[key] = {
              ...file,
              meta: { ...file.meta, name: filename, encoded: true },
              name: filename,
            };
          }
        });
        return normalized;
      },
    });

    instance.use(XHRUpload, {
      id: `doc-intelligence-uploader`,
      endpoint: `${baseUrl}/llm/document/load`,
      method: 'post',
      formData: false,
      headers: (file) => {
        const extraHeaders: Record<string, string> = {
          authorization: `Bearer ${getToken()}`,
        };
        if (file.type) {
          extraHeaders['Content-Type'] = file.type;
        }
        extraHeaders['x-lp-filename'] = file.name;
        updateExtraHeaders(extraHeaders);
        return extraHeaders;
      },
      timeout: 1800 * 1000, // 30 Mins
      limit: 1,
    });

    instance.on('file-added', (file) => {
      setLocalFile({
        file,
        status: 'none',
      });
    });
    instance.on('file-removed', () => {
      setLocalFile(null);
    });
    instance.on('upload-progress', (file) => {
      setLocalFile({
        file,
        status: 'uploading',
      });
    });
    instance.on('upload-success', (file, response) => {
      setLocalFile({
        file,
        status: 'uploading',
      });

      const body = response.body as DtoLoadDocumentResponse;
      setLocalFile({
        file,
        status: 'success',
      });
      const content = body.docs.map((doc) => doc.page_content).join('\n');
      addMessage({ user: 'assistant', text: content, type: 'doc' });
      setError(null);
    });

    instance.on('upload-error', (file, error) => {
      setLocalFile({
        file,
        status: 'failed',
      });
      setError(error.message);
    });

    instance.on('error', (error) => {
      setError(error.message);
    });

    instance.on('restriction-failed', (_, error) => {
      setError(error.message);
    });

    return instance;
  }, [addMessage, baseUrl, docLoaderMap, updateExtraHeaders]);

  const styles = useInstance(() => buildReactSelectStyles());

  const doUpload = async () => {
    if (!localFile) return;
    setError(null);
    uppy.resetProgress();
    if (error) {
      await uppy.retryUpload(localFile.file.id);
    } else {
      await uppy.upload();
    }
  };

  return (
    <div>
      {localFile ? (
        <div className='flex flex-col gap-2'>
          <div className='flex items-center gap-2'>
            <label>{decodeURIComponent(localFile.file.name)}</label>
            <Select<ClientDocLoader>
              isDisabled={localFile.status === 'uploading'}
              className={'w-60'}
              classNamePrefix='select-box-v2'
              options={loaders}
              onChange={(option) => setLoader(option)}
              value={loader}
              styles={styles}
              formatOptionLabel={(option) => option.name}
              isClearable={false}
            />
            <Select<Option<ClientChunkingMethod>>
              isDisabled={localFile.status === 'uploading'}
              className={'w-60'}
              classNamePrefix='select-box-v2'
              options={chunkingMethods}
              onChange={(option) => setChunkingMethod(option?.value ?? null)}
              value={chunkingMethods.find(
                (option) => option.value === chunkingMethod
              )}
              styles={styles}
              isClearable={false}
            />
          </div>
          {loader?.promptable && (
            <div>
              <textarea
                className='field mb-0 resize-y h-20 py-2'
                placeholder='Tell me anything you know about this file'
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <div className='text-2xs text-secondary mt-1'>
                You can use prompt with this loader, try something like, <br />
                <ul className='list-disc list-inside'>
                  <li>Generate the transcript of this speech.</li>
                  <li>Tell me what you see on this image.</li>
                </ul>
              </div>
            </div>
          )}
          {error && <div className='text-red-002 text-sms'>{error}</div>}
          <div className='flex items-center gap-4'>
            <button
              type='button'
              className='btn-primary w-30 h-8 text-sms flex items-center justify-center gap-1'
              onClick={doUpload}
              disabled={localFile.status === 'uploading'}
            >
              <div>Load</div>
              {localFile.status === 'uploading' && (
                <Loading text='' imgClassName='w-5 h-5' />
              )}
            </button>
            <button
              type='button'
              className='btn-secondary w-30 h-8 text-sms'
              disabled={localFile.status === 'uploading'}
              onClick={() => uppy.removeFile(localFile.file.id)}
            >
              Remove
            </button>
          </div>
        </div>
      ) : (
        <label className='btn text-sms text-icon-gray underline cursor-pointer'>
          Upload File
          <div className='hidden'>
            <FileInput uppy={uppy} inputName={'files'} />
          </div>
        </label>
      )}
    </div>
  );
}

type MessageUser = 'assistant' | 'user';

type SimpleMessage = {
  user: MessageUser;
  type: 'doc' | 'general';
  text: string;
  subTexts?: string[];
};

type OutlineMessage = {
  user: MessageUser;
  type: 'outline';
  outline: { topics: { name: string }[] };
};

type Message = SimpleMessage | OutlineMessage;

function MessageEntry(props: { message: SimpleMessage }) {
  const { message } = props;

  const [preview, truncated] = useMemo(() => {
    if (message.text.length <= 200) return [message.text, false];
    return [truncate(message.text, { length: 200 }), true];
  }, [message.text]);

  const [expanded, setExpanded] = useState(false);

  const text = expanded ? message.text : preview;

  return (
    <div className='flex flex-col gap-2'>
      <div className='font-bold text-tertiary text-sm'>{message.user}</div>
      <div className='whitespace-pre-wrap'>{text}</div>
      {truncated && (
        <button
          type='button'
          className='text-primary underline self-start'
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'Show Less' : 'Show More'}
        </button>
      )}
      {message.subTexts?.map((subText, index) => (
        <div
          key={index}
          className='border border-secondary rounded-xl ml-4 p-2 whitespace-pre-wrap'
        >
          {subText}
        </div>
      ))}
    </div>
  );
}

type OutlineForm = OutlineMessage['outline'];

function TopicEntry(props: {
  tIndex: number;
  topic: string;
  onRemove: (index: number) => void;
  generating?: boolean;
  topicOutline?: SchemaTrainingTopicOutline | string;
  blockMap: { [key: string]: unknown };
}) {
  const { tIndex: topicIndex, topicOutline, blockMap } = props;
  const { register } = useFormContext<OutlineForm>();
  return (
    <li className='flex flex-col gap-2'>
      <div className='flex items-center gap-2'>
        <input
          className='field mb-0 rounded-none'
          {...register(`topics.${topicIndex}.name`)}
        />
        <button
          type='button'
          className='text-red-002'
          onClick={() => props.onRemove(topicIndex)}
        >
          <DeleteIcon />
        </button>
      </div>
      {props.generating && !topicOutline && (
        <div className='flex'>
          <Loading text='' imgClassName='w-5 h-5' />
        </div>
      )}
      {match(topicOutline)
        .with(P.nullish, () => null)
        .with(P.string, (s) => <div>{s}</div>)
        .otherwise((o) => (
          <div className='flex flex-col gap-2'>
            {o.candidates.map((candidate, index) => {
              const key = `t.${topicIndex}.b.${index}`;
              const block = blockMap[key];
              return (
                <div className='flex flex-col gap-2' key={index}>
                  <div className='flex items-center gap-1'>
                    <p title={candidate.reason}>
                      Block #{index + 1}: {candidate.blockType}
                      {candidate.blockType === 'slide'
                        ? `, ${candidate.layout}`
                        : ''}
                    </p>
                    {props.generating && !block && (
                      <Loading text='' imgClassName='w-3 h-3' />
                    )}
                  </div>
                  {block ? (
                    <pre className='border border-secondary rounded-none p-2 whitespace-pre-wrap'>
                      {JSON.stringify(block, null, 2)}
                    </pre>
                  ) : null}
                </div>
              );
            })}
          </div>
        ))}
    </li>
  );
}

function OutlineMessageEntry(props: {
  profile: ModelsTrainingProfile | null;
  message: OutlineMessage;
  llmSettings: DtoLLMSettings | undefined;
  searchSettings: DtoLLMSearchSettings;
  sessionId: string;
}) {
  const { profile, message, llmSettings, searchSettings, sessionId } = props;
  const form = useForm<OutlineForm>({
    defaultValues: message.outline,
  });
  const { control } = form;

  const { fields, remove } = useFieldArray({
    control,
    name: 'topics',
  });

  const [blockMap, setBlockMap] = useState<{
    [key: string]: DtoGenerateTrainingBlockResponse | string;
  }>({});
  const [topicOutlineMap, setTopicOutlineMap] = useState<{
    [key: string]: SchemaTrainingTopicOutline | string;
  }>({});
  const [generated, setGenerated] = useState(false);

  const {
    call: generateBlocksIndividually,
    state: { state: generateState1 },
  } = useLiveAsyncCall(async () => {
    if (!profile) return;
    setGenerated(false);
    setPackId(null);
    setBlockMap({});
    setTopicOutlineMap({});
    const promises: Promise<void>[] = [];
    for (const [tIdx, topic] of fields.entries()) {
      const p = new Promise<void>(async (resolve) => {
        let outline: SchemaTrainingTopicOutline | undefined = undefined;
        try {
          const resp = await apiService.training.generateTopicOutline({
            sessionId,
            profileId: profile.id,
            searchSettings,
            topicName: topic.name,
            llmSettings,
          });
          outline = resp.data.outline;
          setTopicOutlineMap((prev) => ({
            ...prev,
            [`t.${tIdx}`]: resp.data.outline,
          }));
        } catch (error) {
          setTopicOutlineMap((prev) => ({
            ...prev,
            [`${tIdx}`]: err2s(error) ?? 'unknown error',
          }));
        }
        if (outline) {
          for (const [bIdx, candidate] of outline.candidates.entries()) {
            try {
              const resp = await apiService.training.generateTrainingBlock({
                sessionId,
                profileId: profile.id,
                blockType: candidate.blockType,
                slideLayout: candidate.layout,
                searchSettings,
                topicName: topic.name,
                reason: candidate.reason,
                llmSettings,
              });
              setBlockMap((prev) => ({
                ...prev,
                [`t.${tIdx}.b.${bIdx}`]: resp.data,
              }));
            } catch (error) {
              setBlockMap((prev) => ({
                ...prev,
                [`t.${tIdx}.b.${bIdx}`]: err2s(error) ?? 'unknown error',
              }));
            }
          }
        }
        resolve();
      });
      promises.push(p);
    }
    await Promise.all(promises);
    setGenerated(true);
  });

  const {
    call: generateBlocksTogether,
    state: { state: generateState2 },
  } = useLiveAsyncCall(async () => {
    if (!profile) return;
    setGenerated(false);
    setPackId(null);
    setBlockMap({});
    setTopicOutlineMap({});
    const promises: Promise<void>[] = [];
    for (const [tIdx, topic] of fields.entries()) {
      const p = new Promise<void>(async (resolve) => {
        let outline: SchemaTrainingTopicOutline | undefined = undefined;
        try {
          const resp = await apiService.training.generateTopicOutline({
            sessionId,
            profileId: profile.id,
            searchSettings,
            topicName: topic.name,
            llmSettings,
          });
          outline = resp.data.outline;
          setTopicOutlineMap((prev) => ({
            ...prev,
            [`t.${tIdx}`]: resp.data.outline,
          }));
        } catch (error) {
          setTopicOutlineMap((prev) => ({
            ...prev,
            [`${tIdx}`]: err2s(error) ?? 'unknown error',
          }));
        }

        if (outline) {
          try {
            const resp = await apiService.training.generateTopicBlocks({
              sessionId,
              profileId: profile.id,
              searchSettings,
              topicName: topic.name,
              llmSettings,
              outline,
            });
            const blocks = resp.data.blocks;

            for (const [bIdx, block] of blocks.entries()) {
              setBlockMap((prev) => ({
                ...prev,
                [`t.${tIdx}.b.${bIdx}`]: {
                  blocks: [block],
                },
              }));
            }
          } catch (error) {
            setBlockMap((prev) => ({
              ...prev,
              [`t.${tIdx}.b.0`]: err2s(error) ?? 'unknown error',
            }));
          }
        }

        resolve();
      });
      promises.push(p);
    }
    await Promise.all(promises);
    setGenerated(true);
  });

  const generating = generateState1.isRunning || generateState2.isRunning;

  const [packId, setPackId] = useState<string | null>(null);

  const {
    call: makingTrainingCourse,
    state: { state: makingState },
  } = useLiveAsyncCall(async () => {
    const topics: { name: string; key: string }[] = [];
    for (const [tIdx, topic] of fields.entries()) {
      topics.push({ name: topic.name, key: `t.${tIdx}` });
    }

    const req: DtoMakeTrainingCourseRequest = {
      name: `Training Course ${new Date().toISOString()}`,
      groups: [],
    };

    for (const topic of topics) {
      const outline = topicOutlineMap[topic.key];
      if (typeof outline === 'string') {
        continue;
      }
      const group: DtoTrainingCourseGroup = { name: topic.name, blocks: [] };
      for (const [bIdx] of outline.candidates.entries()) {
        const blockKey = `${topic.key}.b.${bIdx}`;
        const blockResp = blockMap[blockKey];
        if (!blockResp || typeof blockResp === 'string') {
          continue;
        }
        for (const block of blockResp.blocks) {
          group.blocks.push(block);
        }
      }
      req.groups.push(group);
    }

    const resp = await apiService.training.makeTrainingCourse(req);
    setPackId(resp.data.packId);
  });

  return (
    <FormProvider {...form}>
      <div className='flex flex-col gap-2'>
        <div className='font-bold text-tertiary text-sm'>{message.user}</div>
        <div className='flex flex-col gap-6 w-4/5'>
          {fields.map((topic, index) => (
            <TopicEntry
              key={`t.${index}`}
              tIndex={index}
              topic={topic.name}
              generating={generating}
              onRemove={remove}
              topicOutline={topicOutlineMap[`t.${index}`]}
              blockMap={blockMap}
            />
          ))}
          <div className='flex items-center gap-8'>
            <button
              type='button'
              className='text-primary underline disabled:text-secondary disabled:no-underline flex items-center gap-1'
              onClick={
                profile?.topicBlockOverview
                  ? generateBlocksTogether
                  : generateBlocksIndividually
              }
              disabled={generating || !profile}
            >
              <div>Generate Blocks</div>
              {generating && <Loading text='' imgClassName='w-5 h-5' />}
            </button>
            {packId ? (
              <Link
                to={`/trainings/${packId}/edit`}
                target='_blank'
                className='text-tertiary underline'
              >
                Open Course
              </Link>
            ) : (
              <button
                type='button'
                className='text-tertiary underline disabled:text-secondary disabled:no-underline flex items-center gap-1'
                onClick={makingTrainingCourse}
                disabled={!generated || makingState.isRunning}
              >
                <div>Make Training Course</div>
                {makingState.isRunning && (
                  <Loading text='' imgClassName='w-5 h-5' />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </FormProvider>
  );
}

type RevelantSearchFormData = SearchDocsParams;

function RevelantSearch(props: {
  namespace: string;
  addMessage: (message: Message) => void;
  enabled: boolean;
}) {
  const { namespace, addMessage, enabled } = props;
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm<RevelantSearchFormData>({
    defaultValues: {
      query: '',
      limit: 20,
      scoreThreshold: 0.6,
      namespace,
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    addMessage({ user: 'user', text: data.query, type: 'general' });
    const resp = await apiService.aiDoc.searchDocs(data);
    const contexts = resp.data.docs.map(
      (doc) => `<context>${doc.page_content}</context>`
    );
    const resp2 = await apiService.openai.createChatCompletion({
      messages: [
        {
          role: 'system',
          content:
            "You are going to answer the user's question based on the relevant information",
        },
        {
          role: 'assistant',
          content: [`Here are the relevant information:`, ...contexts].join(
            '\n\n'
          ),
        },
        {
          role: 'user',
          content: data.query,
        },
      ],
    });
    addMessage({
      user: 'assistant',
      text: 'Here are the relevant information',
      type: 'general',
      subTexts: resp.data.docs.map(
        (doc) => `Score: ${doc.score}\n${doc.page_content}`
      ),
    });
    if (resp2.data.choices.length > 0) {
      addMessage({
        user: 'assistant',
        text: 'Here is the summary',
        type: 'general',
        subTexts: [resp2.data.choices[0].message.content],
      });
    }
  });
  return (
    <form onSubmit={onSubmit}>
      <div className='flex flex-col gap-2'>
        <div className='flex items-center gap-2'>
          <div className='flex items-center gap-2'>
            <label className='font-bold text-sm'>Limit</label>
            <input
              className='field mb-0 h-8 rounded-md'
              {...register('limit')}
              type='number'
              min={1}
              max={100}
            />
          </div>
          <div className='flex items-center gap-2'>
            <label className='font-bold text-sm whitespace-nowrap'>
              Score Threshold
            </label>
            <input
              className='field mb-0 h-8 rounded-md'
              {...register('scoreThreshold')}
              type='number'
              min={0}
              max={1}
              step={0.01}
            />
          </div>
        </div>
        <div className='flex flex-col gap-2'>
          <textarea
            className='field mb-0 resize-none h-20 py-2'
            {...register('query', { required: true })}
            placeholder='Search for relevant information'
          />
        </div>
        <button
          type='submit'
          className='btn-primary w-30 h-8 text-sms flex items-center justify-center gap-1'
          disabled={!enabled || isSubmitting}
        >
          <div>Search</div>
          {isSubmitting && <Loading text='' imgClassName='w-5 h-5' />}
        </button>
      </div>
    </form>
  );
}

type GenerateOutlineFormData = Omit<
  DtoGenerateTrainingOutlineRequest,
  'context' | 'sessionId'
> & { searchSettings: Omit<DtoLLMSearchSettings, 'namespace'> };

function GenerateOutline(props: {
  docs: string[];
  addMessage: (message: Message) => void;
  enabled: boolean;
  llmProviders: ClientLLMProviderItem[];
  setProfile: (profile: ModelsTrainingProfile | null) => void;
  setLLMSettings: (settings: DtoLLMSettings | undefined) => void;
  defaultSearchSettings: Omit<DtoLLMSearchSettings, 'namespace'>;
  setSearchSettings: (
    settings: Omit<DtoLLMSearchSettings, 'namespace'>
  ) => void;
  setSessionId: (sessionId: string) => void;
}) {
  const { docs, addMessage, enabled, setLLMSettings } = props;
  const {
    control,
    watch,
    register,
    formState: { isSubmitting, errors },
    handleSubmit,
    setError,
  } = useForm<GenerateOutlineFormData>({
    defaultValues: {
      searchSettings: props.defaultSearchSettings,
    },
  });

  const provider = watch('llmSettings.provider');
  const model = watch('llmSettings.model');

  useEffect(() => {
    setLLMSettings({
      provider,
      model,
    });
  }, [provider, model, setLLMSettings]);

  const [profile, setProfile] = useState<ModelsTrainingProfile | null>(null);

  const onSubmit = handleSubmit(async (data) => {
    const sessionId = uuidv4();
    props.setProfile(profile);
    props.setSessionId(sessionId);
    props.setSearchSettings(data.searchSettings);
    addMessage({
      user: 'user',
      text: `Generate Outline with ${profile?.name}`,
      type: 'general',
    });
    try {
      const resp = await apiService.training.generateTrainingOutline({
        ...data,
        sessionId,
        context: docs.map((doc) => `<context>${doc}</context>`).join('\n'),
      });
      addMessage({
        user: 'assistant',
        type: 'outline',
        outline: {
          topics: resp.data.outline.topics.map((topic) => ({ name: topic })),
        },
      });
    } catch (error) {
      setError('root.serverError', {
        message: err2s(error) ?? 'Something went wrong, please try again.',
      });
    }
  });

  return (
    <form className='flex flex-col gap-2' onSubmit={onSubmit}>
      <div className='flex items-center gap-2'>
        <div className='w-6/12'>
          <p className='text-sms mb-1 font-bold'>Training Prompt Profile:</p>
          <Controller<GenerateOutlineFormData, 'profileId'>
            control={control}
            rules={{
              required: true,
            }}
            name='profileId'
            render={({ field }) => (
              <div className='w-full flex items-center gap-1'>
                <TrainingProfilePicker
                  profile={field.value}
                  onChange={(t) => {
                    field.onChange(t.id);
                    setProfile(t);
                  }}
                />
              </div>
            )}
          />
        </div>
        <div className='w-3/12'>
          <p className='text-sms mb-1 font-bold'>Overwrite Provider:</p>
          <Controller<GenerateOutlineFormData, 'llmSettings.provider'>
            control={control}
            name='llmSettings.provider'
            render={({ field }) => (
              <LLMProviderSelect
                providers={props.llmProviders}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        </div>
        <div className='w-3/12'>
          <p className='text-sms mb-1 font-bold'>Overwrite Model:</p>
          <Controller<GenerateOutlineFormData, 'llmSettings.model'>
            control={control}
            name='llmSettings.model'
            render={({ field }) => (
              <LLMModelSelect
                value={field.value}
                provider={provider}
                onChange={field.onChange}
                providers={props.llmProviders}
              />
            )}
          />
        </div>
      </div>
      <div className='flex items-center gap-2'>
        <div className='w-1/3'>
          <label className='text-sms mb-1 font-bold'>Recall Limit:</label>
          <input
            className='field mb-0 h-10 rounded-md'
            {...register('searchSettings.limit', { valueAsNumber: true })}
            type='number'
            min={1}
            max={100}
          />
        </div>
        <div className='w-1/3'>
          <label className='text-sms mb-1 font-bold'>
            Recall Score Threshold:
          </label>
          <input
            className='field mb-0 h-10 rounded-md'
            {...register('searchSettings.scoreThreshold', {
              valueAsNumber: true,
            })}
            type='number'
            min={0}
            max={1}
            step={0.01}
          />
        </div>
      </div>
      <div className='flex items-center gap-2'>
        <div>
          {profile ? (
            <TrainingProfileQuickLook profile={profile} inlineEdit />
          ) : null}
        </div>
      </div>
      <button
        type='submit'
        className='btn-primary w-30 h-8 text-sms flex items-center justify-center gap-1'
        disabled={!enabled || isSubmitting || !profile}
      >
        <div>Generate</div>
        {isSubmitting && <Loading text='' imgClassName='w-5 h-5' />}
      </button>
      {errors.root?.serverError && (
        <div className='text-sms text-red-002'>
          {errors.root.serverError.message}
        </div>
      )}
    </form>
  );
}

export function TrainingAIPlayground(props: {
  baseUrl: string;
  loaderConfigItems: ClientDocLoaderConfigItem[];
  chunkingMethods: ClientChunkingMethod[];
  llmProviders: ClientLLMProviderItem[];
}) {
  const namespace = useInstance(() => {
    const now = new Date();
    return `${now.toISOString().replaceAll(/[-:]/g, '_')}_${now.getTime()}`;
  });
  const [messages, setMessages] = useState<Message[]>([]);
  const addMessage = useLiveCallback((message: Message) => {
    setMessages((prev) => [...prev, message]);
  });
  const [profile, setProfile] = useState<ModelsTrainingProfile | null>(null);
  const [llmSettings, setLLMSettings] = useState<DtoLLMSettings | undefined>();
  const [searchSettings, setSearchSettings] = useState<
    Omit<DtoLLMSearchSettings, 'namespace'>
  >({ limit: 30, scoreThreshold: 0.75 });
  const [sessionId, setSessionId] = useState<string | null>(null);

  const convoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (convoRef.current) {
      convoRef.current.scrollTop = convoRef.current.scrollHeight;
    }
  }, [messages.length]);

  const numOfAssistantMessages = messages.filter(
    (msg) => msg.user === 'assistant'
  ).length;

  const docs = useMemo(
    () =>
      messages
        .filter((msg): msg is SimpleMessage => msg.type === 'doc')
        .map((msg) => msg.text),
    [messages]
  );

  return (
    <div className='w-full flex-grow max-h-[85%] flex text-white text-sms gap-6'>
      <div className='w-2/5 flex flex-col h-full gap-4'>
        <div className='w-full flex flex-col border border-secondary rounded-xl p-2 h-60 flex-shrink-0'>
          <Uploader {...props} namespace={namespace} addMessage={addMessage} />
        </div>
        <div className='w-full flex flex-col gap-6 border border-secondary rounded-xl p-2 flex-grow flex-shrink-0'>
          <CollapsibleSection title='Revelant Search' defaultOpened={false}>
            <RevelantSearch
              namespace={namespace}
              addMessage={addMessage}
              enabled={numOfAssistantMessages > 0}
            />
          </CollapsibleSection>
          <CollapsibleSection title='Generate Outline' defaultOpened={true}>
            <GenerateOutline
              docs={docs}
              addMessage={addMessage}
              enabled={numOfAssistantMessages > 0}
              llmProviders={props.llmProviders}
              setProfile={setProfile}
              setLLMSettings={setLLMSettings}
              defaultSearchSettings={searchSettings}
              setSearchSettings={setSearchSettings}
              setSessionId={setSessionId}
            />
          </CollapsibleSection>
        </div>
      </div>
      <div className='flex flex-col w-3/5 h-full border border-secondary rounded-xl p-2'>
        <div ref={convoRef} className='flex-grow overflow-scroll'>
          <div className='flex flex-col gap-4'>
            {messages.map((msg, index) =>
              match(msg)
                .with({ type: 'outline' }, (msg) => (
                  <OutlineMessageEntry
                    profile={profile}
                    key={index}
                    message={msg}
                    llmSettings={llmSettings}
                    searchSettings={{ ...searchSettings, namespace }}
                    sessionId={sessionId ?? uuidv4()}
                  />
                ))
                .otherwise((m) => <MessageEntry key={index} message={m} />)
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
