import { useLoaderData } from '@remix-run/react';

import { useUserAnalytics } from '../analytics/user';
import { AdminAccess } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { ProvidersList } from '../components/ProvidersList';
import { TrainingAIPlayground } from '../components/Training/Playground';
import { UserContextProvider } from '../components/UserContext';
import config from '../config';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';
import { apiService } from '../services/api-service';

export async function clientLoader() {
  const resp1 = await apiService.aiDoc.fetchConfig();
  const resp2 = await apiService.aiGeneral.getGlobalSettings();
  return {
    loaderConfigItems: resp1.data.loaderConfigItems,
    chunkingMethods: resp1.data.chunkingMethods,
    llmProviders: resp2.data.providers,
  };
}

export function Component() {
  const { loaderConfigItems, chunkingMethods, llmProviders } =
    useLoaderData<typeof clientLoader>();

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <AdminAccess />,
  ];

  return (
    <ProvidersList providers={providers}>
      <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
        <AdminToolkitNav />

        <header className='text-white text-3.5xl font-bold'>
          Training Intelligence Playground
        </header>
        <TrainingAIPlayground
          baseUrl={config.api.baseUrl}
          loaderConfigItems={loaderConfigItems}
          chunkingMethods={chunkingMethods}
          llmProviders={llmProviders}
        />
      </AdminView>
      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
