import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import { type SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import { type ModelsTrainingProfile } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { buildReactSelectStyles } from '../../utils/react-select';

async function search(
  q: string,
  callback: (items: ModelsTrainingProfile[]) => void
) {
  const resp = await apiService.trainingProfile.listProfiles();
  const items = resp.data.profiles.filter((item) =>
    item.name.toLowerCase().includes(q.toLowerCase())
  );
  callback(items);
}

export function TrainingProfilePicker(props: {
  profile?: string | null;
  onChange: (value: ModelsTrainingProfile) => void;
  disabled?: boolean;
}): JSX.Element {
  const styles = useMemo(
    () =>
      buildReactSelectStyles({
        override: {
          control: { height: '100%' },
        },
      }),
    []
  );

  const [loadedOptions, setLoadedOptions] = useState<
    ModelsTrainingProfile[] | null
  >(null);

  const debounceSearch = useInstance(() => debounce(search, 250));

  const loadOptions = (
    q: string,
    callback: (items: ModelsTrainingProfile[]) => void
  ): void => {
    debounceSearch(q, (items) => {
      setLoadedOptions(items);
      callback(items);
    });
  };

  const handleSingleChange = (option: SingleValue<ModelsTrainingProfile>) => {
    if (option) {
      props.onChange(option);
    }
  };

  const selectedValue = useMemo(() => {
    if (!props.profile) return null;
    return loadedOptions?.find((item) => item.id === props.profile) ?? null;
  }, [loadedOptions, props.profile]);

  return (
    <AsyncSelect<ModelsTrainingProfile, false>
      placeholder={`Select prompt profile`}
      styles={styles}
      cacheOptions
      defaultOptions
      value={selectedValue}
      loadOptions={loadOptions}
      classNamePrefix='select-box-v2'
      className='w-full'
      noOptionsMessage={(obj) => {
        if (!obj.inputValue) return 'Start typing to search';
        return 'No prompt profile matched';
      }}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      onChange={handleSingleChange}
      isDisabled={props.disabled}
    />
  );
}
